import firebase from 'firebase'

//2. Initialize app with the config vars
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_TEOLLISUUSLIITTO_API_KEY,
  authDomain: 'kyselylomake.firebaseapp.com',
  projectId: 'kyselylomake',
  storageBucket: 'kyselylomake.appspot.com',
  messagingSenderId: '56721419413',
  appId: '1:56721419413:web:05bdd772c07d3b13fd3f9c',
  measurementId: 'G-CFZY0KGYYY',
})

//3. export it for use
export default app
