import { gql } from '@apollo/client'

export const ALL_ORGANIZATIONS = gql`
  query getAllOrganizations(
    $sorting: String
    $direction: String
    $currentPage: Float
    $koko: Float
    $maakunnat: [Maakunnat!]
    $omistuspohja: [Omistuspohjat!]
    $active: Float
  ) {
    organizations(
      sorting: $sorting
      direction: $direction
      currentPage: $currentPage
      koko: $koko
      maakunnat: $maakunnat
      omistuspohja: $omistuspohja
      active: $active
    ) {
      organizations {
        id
        nimi
        ytunnus
        users {
          id
          etunimi
          sukunimi
          email
        }
        maakunta
        koko
        omistuspohja
        active
        patteristot {
          id
          created_at
        }
      }
      pageInfo {
        totalPages
      }
    }
  }
`
