import { gql } from '@apollo/client'

export const VASTAUKSET = gql`
  query vastaukset($patteristoId: String!) {
    vastaus: vastaukset(id: $patteristoId) {
      index
      otsikko
      aliotsikko
      kysymys
      kaTyontekija
      kaTyonantaja
      kaLuottamusmies
      khTyonantaja
      khTyontekija
      khLuottamusmies
      reallyBadTyonantaja
      badTyonantaja
      cantTellTyonantaja
      goodTyonantaja
      reallyGoodTyonantaja
      reallyBadTyontekija
      badTyontekija
      cantTellTyontekija
      goodTyontekija
      reallyGoodTyontekija
      reallyBadLuottamusmies
      badLuottamusmies
      cantTellLuottamusmies
      goodLuottamusmies
      reallyGoodLuottamusmies
    }
  }
`
