import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'

import Select from 'react-select'
import { FormattedMessage, useIntl } from 'react-intl'
import { Label } from './Label'
import { CREATE_USER } from '../mutations/create_user'
import { FaPlus } from 'react-icons/fa'
import { colors } from '../theme/colors'
const content = <Flex py={4}>asd</Flex>

export const CreateUserModal = props => {
  const refetch = props.refetch
  const [createUser, { loading: mutationLoading }] = useMutation(CREATE_USER)
  const intl = useIntl()

  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const [etunimi, setEtunimi] = useState('')
  const [sukunimi, setSukunimi] = useState('')

  const toast = useToast()

  const sendCreateUser = async () => {
    try {
      await createUser({
        variables: {
          //@ts-ignore
          email,
          isAdmin,
          etunimi,
          sukunimi,
          isActive,
        },
      })
      await refetch()
    } catch (e) {}
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <IconButton
        aria-label="Add"
        variant="primary-round"
        onClick={() => {
          setEmail('')
          setEtunimi('')
          setSukunimi('')
          setIsActive(true)
          setIsAdmin(false)
          onOpen()
        }}
        icon={<FaPlus color={colors.brand.white} />}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mt={10}>
            <Stack p={4} spacing={4}>
              <Heading as="h2" size="md">
                <FormattedMessage id="create_user" defaultMessage="Luo Käyttäjä" />
              </Heading>
              <Box>
                <Label
                  text={intl.formatMessage({
                    id: 'email',
                    defaultMessage: 'Sähköposti',
                  })}
                />
                <Input value={email} onChange={e => setEmail(e.target.value)} type="email" />
              </Box>
              <Box>
                <Label
                  text={intl.formatMessage({
                    id: 'firstname',
                    defaultMessage: 'Etunimi',
                  })}
                />
                <Input value={etunimi} onChange={e => setEtunimi(e.target.value)} />
              </Box>
              <Box>
                <Label
                  text={intl.formatMessage({
                    id: 'surname',
                    defaultMessage: 'Sukunimi',
                  })}
                />
                <Input value={sukunimi} onChange={e => setSukunimi(e.target.value)} />
              </Box>
              <Box>
                <Label
                  text={intl.formatMessage({
                    id: 'isAdmin',
                    defaultMessage: 'On Admin?',
                  })}
                />

                <Select
                  w={48}
                  onChange={e => setIsAdmin(e.value)}
                  defaultValue={{ value: false, label: 'Ei' }}
                  //@ts-ignore
                  options={[
                    { value: false, label: 'Ei' },
                    { value: true, label: 'Kyllä' },
                  ]}
                />
              </Box>
              <Box>
                <Label
                  text={intl.formatMessage({
                    id: 'isActive',
                    defaultMessage: 'Aktiivinen?',
                  })}
                />

                <Select
                  w={48}
                  onChange={e => setIsActive(e.value)}
                  defaultValue={{ value: true, label: 'Kyllä' }}
                  //@ts-ignore
                  options={[
                    { value: false, label: 'Ei' },
                    { value: true, label: 'Kyllä' },
                  ]}
                />
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              aria-label="Create User"
              disabled={email ? false : true}
              alignSelf="right"
              onClick={() => {
                sendCreateUser()
                onClose()
              }}
            >
              Luo
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
