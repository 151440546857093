import { gql } from '@apollo/client'

export const USER_REGISTER = gql`
  mutation userRegister(
    $maakunta: Maakunnat!
    $koko: Float!
    $omistuspohja: Omistuspohjat!
    $ytunnus: String!
    $etunimi: String!
    $sukunimi: String!
  ) {
    userRegister(
      data: {
        etunimi: $etunimi
        sukunimi: $sukunimi
        ytunnus: $ytunnus
        maakunta: $maakunta
        koko: $koko
        omistuspohja: $omistuspohja
      }
    ) {
      id
    }
  }
`
