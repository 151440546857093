import { gql } from '@apollo/client'

export const CREATE_SURVEY = gql`
  mutation createSurvey(
    $collectionId: String!
    $organizationId: String!
    $deadline: DateTime!
    $valmennuspaiva: Boolean
    $maxVastaukset: Float!
  ) {
    createPatteristo(
      data: {
        collectionId: $collectionId
        organizationId: $organizationId
        deadline: $deadline
        valmennuspaiva: $valmennuspaiva
        maxVastaukset: $maxVastaukset
      }
    ) {
      id
    }
  }
`
