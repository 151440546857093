import { Box, Text } from '@chakra-ui/react'
import { ResultChart } from './ResultChart'
import { ResultTable } from './ResultTable'

export function ReportItem(props) {
  return (
    <>
      <ResultTable data={props.queryData} id={props.id} />
      <Box h="250px">
        <ResultChart data={[props.queryData]} />
      </Box>
      <Text fontSize="sm" fontStyle="italic">
        Työnantajan edustajat: keskiarvo {props.queryData.kaTyonantaja}, keskihajonta{' '}
        {props.queryData.khTyonantaja}
      </Text>
      <Text fontSize="sm" fontStyle="italic">
        {' '}
        Työntekijät: keskiarvo {props.queryData.kaTyontekija}, keskihajonta{' '}
        {props.queryData.khTyontekija}
      </Text>
      <Text fontSize="sm" fontStyle="italic">
        {props.queryData.kaLuottamusmies === -1
          ? ''
          : 'Luottamushenkilöt: keskiarvo ' +
            props.queryData.kaLuottamusmies +
            ', keskihajonta ' +
            props.queryData.khLuottamusmies}
      </Text>
    </>
  )
}
