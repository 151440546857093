import { gql } from '@apollo/client'

export const CSV = gql`
  query csv($patteristoId: String!) {
    csv(id: $patteristoId) {
      rooli
      vastaukset {
        value
        question {
          index
        }
      }
    }
    patteristo(id: $patteristoId) {
      collection {
        kysymykset {
          index
        }
      }
    }
  }
`
