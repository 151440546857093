import { gql } from '@apollo/client'

export const CREATE_VASTAUS = gql`
  mutation createVastaus(
    $patteristoId: String!
    $vastaukset: [QuestionVastausInput!]!
    $rooli: Roolit!
  ) {
    createVastaus(data: { patteristoId: $patteristoId, vastaukset: $vastaukset, rooli: $rooli })
  }
`
