import { Box, Button, Flex, Heading, Spinner, Text } from '@chakra-ui/react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { ChartLegend } from '../components/ChartLegend'
import { ReportItem } from '../components/ReportItem'

import '../theme/reportAndResultStyles.css'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { VASTAUKSET } from '../queries/vastaukset'
import { CSV } from '../queries/csv'
import PowerPointGeneratorButton from '../components/PowerPointGenerator'

function Report() {
  const history = useHistory()
  //@ts-ignore
  const patteristo = history.location.state.id
  // eslint-disable-next-line
  const {
    loading: queryLoading,
    data: queryData,
    error: queryError,
  } = useQuery(VASTAUKSET, {
    variables: { patteristoId: patteristo },
  })
  const [getCSV, { data: csvData }] = useLazyQuery(CSV)
  useEffect(() => {
    if (csvData) {
      const reduced = csvData.csv.map(row => {
        let csvRow = csvData.patteristo.collection.kysymykset.reduce((acc, cur) => {
          acc[cur.index] = ' '
          return acc
        }, {})
        row.vastaukset.forEach(e => {
          csvRow[e.question.index] = e.value
        })
        csvRow.role = row.rooli
        return csvRow
      })
      if (reduced.length > 0) {
        let result = ''
        Object.keys(reduced[0]).forEach(e => (result += `${e}, `))

        result = result.slice(0, -2)
        result += '\r\n'
        const asd = reduced.map(e =>
          Object.keys(e)
            .reduce((p, c) => (p += `${e[c]}, `), '')
            .slice(0, -2)
        )

        result += asd.join('\r\n')
        const csvContent = `data:text/csv;charset=utf-8,${result}`
        const encodedUri = encodeURI(csvContent)
        window.open(encodedUri)
      }

      // let result = ''
      // result +=
      //   'index, kysymys, aliotsikko, kaTyontekija, khTyontekija, kaTyonantaja, khTyonantaja, reallyBadTyonantaja, badTyonantaja, cantTellTyonantaja, goodTyonantaja, reallyGoodTyonantaja, reallyBadTyontekija, badTyontekija, cantTellTyontekija, goodTyontekija, reallyGoodTyontekija\r\n'
      // queryData.vastaus.forEach(
      //   e =>
      //     (result += `${e.index}, ${e.kysymys}, ${e.aliotsikko}, ${e.kaTyontekija}, ${e.khTyontekija}, ${e.kaTyonantaja}, ${e.khTyonantaja}, ${e.reallyBadTyonantaja}, ${e.badTyonantaja}, ${e.cantTellTyonantaja}, ${e.goodTyonantaja}, ${e.reallyGoodTyonantaja}, ${e.reallyBadTyontekija}, ${e.badTyontekija}, ${e.cantTellTyontekija}, ${e.goodTyontekija}, ${e.reallyGoodTyontekija}\r\n`)
      // )
      // const csvContent = `data:text/csv;charset=utf-8,${result}`
      // const encodedUri = encodeURI(csvContent)
      // window.open(encodedUri)
    }
  }, [csvData])

  if (queryLoading) {
    return <Spinner size="xl" />
  }
  if (queryError) {
    console.log(JSON.stringify(queryError, null, 2))
    return (
      <div>
        <h1>Virhe! Fel! Error!</h1>
        <div>
          {JSON.stringify(queryError, null, 2)} <ChartLegend />
        </div>
      </div>
    )
  }

  /*


  */
  const csv = async () => {
    await getCSV({ variables: { patteristoId: patteristo } })
    /*

      index
      otsikko
      aliotsikko
      kysymys
      kaTyontekija
      kaTyonantaja
      khTyonantaja
      khTyontekija
      reallyBadTyonantaja
      badTyonantaja
      cantTellTyonantaja
      goodTyonantaja
      reallyGoodTyonantaja
      reallyBadTyontekija
      badTyontekija
      cantTellTyontekija
      goodTyontekija
      reallyGoodTyontekija


    */
  }

  return (
    <Box textAlign="left">
      <Box className="printFirstPage">
        <Heading as="h1" mb={4}>
          Raportti
        </Heading>

        <Box fontSize="md" pb={14}>
          <Text>
            Kyselyn alkupäivämäärä: {/* @ts-ignore*/}
            {new Date(history.location.state.entry.created_at).toLocaleDateString('fi-FI')}
          </Text>
          <Text>
            {/* @ts-ignore*/}
            Deadline: {new Date(history.location.state.entry.deadline).toLocaleDateString('fi-FI')}
          </Text>
          <Text>
            Vastauksia: {/* @ts-ignore*/}
            {history.location.state.entry.vastaukset.length}/{/* @ts-ignore*/}
            {history.location.state.entry.maxVastaukset}
          </Text>
          <Flex maxW="100px" flexDir="column" className="no-print">
            <Button aria-label="Tulosta" size="sm" mb={1} variant="primary" onClick={window.print}>
              Tulosta
            </Button>
            <Button aria-label="Lataa CSV" size="sm" mb={6} variant="primary" onClick={() => csv()}>
              Lataa CSV
            </Button>
            <Box>
              <PowerPointGeneratorButton data={queryData.vastaus} />
            </Box>
          </Flex>
        </Box>
      </Box>
      {queryData.vastaus
        .reduce((r, e, i) => (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r, [])
        .map(e => (
          <>
            {e.map(a => (
              <Box mb={12} w="100%" className="printSection">
                <Text fontSize="xs" fontWeight="semibold" textTransform="uppercase">
                  {a.otsikko} &bull; {a.aliotsikko}
                </Text>
                <Heading as="h2" fontSize="xl" my={1}>
                  {a.kysymys}
                </Heading>
                <ReportItem id={a.index} queryData={a} />
              </Box>
            ))}
          </>
        ))}
    </Box>
  )
}

export default Report
