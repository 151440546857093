import { useQuery } from '@apollo/client'
import { Box, Button, Heading, Spinner, Stack, Text, useToast } from '@chakra-ui/react'
import { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'
import { ChartLegend } from '../components/ChartLegend'
import MultiSelect from '../components/MultiSelect'
import PowerPointGeneratorButton from '../components/PowerPointGenerator'
import { ResultChart } from '../components/ResultChart'
import { useOrganization } from '../contexts/OrganizationContext'
import Maakunnat from '../enums/Maakunnat'
import { OmistusPohja } from '../enums/Omistuspohja'
import { AnswerEntity } from '../models/AnswerEntity'
import { Survey } from '../models/Survey'
import { ALL_PATTERISTO_FOR_ORGANIZATION } from '../queries/all_patteristo_for_organization'
import { INDIVIDUAL_ANSWERS } from '../queries/individual_answers'
import '../theme/reportAndResultStyles.css'

function AdminResults(): ReactElement {
  const { organization, setOrganization } = useOrganization()

  const history = useHistory()
  //@ts-ignore
  const currentSurvey = history.location.state.entry as Survey
  const surveyData = useQuery(ALL_PATTERISTO_FOR_ORGANIZATION, {
    variables: { organizationId: organization },
    pollInterval: 30000,
  })

  const [kyselyt, setKyselyt] = useState([])
  const [kyselyid, setKyselyid] = useState()
  const params = useParams<Record<string, string>>()
  const patteristo = params.id

  const [maakunnat, setMaakunnat] = useState(Object.keys(Maakunnat))
  const [koko, setKoko] = useState(0)
  const [omistuspohja, setOmistuspohja] = useState(Object.keys(OmistusPohja))

  const [filters, setFilters] = useState(null)
  const answerData = useQuery(INDIVIDUAL_ANSWERS, {
    variables: { patteristoId: patteristo, filters: filters },
  })
  const [vastaus, setVastaukset] = useState<AnswerEntity | undefined>()
  const toast = useToast()

  useEffect(() => {
    setKyselyt(
      surveyData.data?.patteristot.map((kysely: Survey) => ({
        id: kysely.id,
        nimi: `${new Date(kysely.created_at).toLocaleDateString('fi-FI')} ${
          kysely.vastaukset.length
        }/${kysely.maxVastaukset}`,
      }))
    )
  }, [surveyData])

  useEffect(() => {
    if (filters && answerData.data) {
      setVastaukset(answerData.data.vastaus[0])
      if (
        !(
          answerData.data.vastaus.some((e: AnswerEntity) => e.vaTyontekija > 0) ||
          answerData.data.vastaus.some((e: AnswerEntity) => e.vaTyonantaja > 0)
        ) &&
        !kyselyid
      ) {
        toast({
          title: 'Verrokkiryhmässä on liian vähän vastauksia!',
          status: 'warning',
          position: 'top',
          duration: 10000,
          isClosable: true,
        })
      }
    }
  }, [filters, answerData.data])

  if (answerData.loading) {
    return (
      <Box textAlign="center">
        <Spinner size="xl" />
      </Box>
    )
  }
  const compareWithCustomGroup = () => {
    //@ts-ignore
    setFilters({ koko, maakunta: [...maakunnat.map(a => a)], omistuspohja })
  }

  const compareWithOwnSurveys = () => {
    //@ts-ignore
    setFilters({ koko, maakunta: [...maakunnat.map(a => a)], omistuspohja, kyselyId: kyselyid })
  }

  return (
    <Box textAlign="left">
      <Heading as="h1" mb={4}>
        Vastaukset
      </Heading>
      <Box fontSize="md" pb={14}>
        <Text>
          Kyselyn alkupäivämäärä:
          {new Date(currentSurvey.created_at).toLocaleDateString('fi-FI')}
        </Text>
        <Text>Deadline: {new Date(currentSurvey.deadline).toLocaleDateString('fi-FI')}</Text>
        <Text>
          Vastauksia:
          {currentSurvey.vastaukset.length}/{currentSurvey.maxVastaukset}
        </Text>
        <Box className="no-print">
          <Box mt={4} mb={8}>
            <Button
              aria-label="Tulosta raportti"
              display="inline-block"
              mr={4}
              variant="primary"
              onClick={window.print}
            >
              Tulosta raportti
            </Button>
          </Box>
          <Box mt={4} mb={8}>
            <PowerPointGeneratorButton data={answerData.data.vastaus} />
          </Box>
          <Heading as="h2" size="sm" mb={2}>
            Valitse vertailuryhmä
          </Heading>
          <Stack isInline align="flex-end">
            <div style={{ width: '200px' }}>
              <Text>Maakunnat</Text>
              <MultiSelect
                //@ts-ignore
                isMulti={true}
                allowSelectAll={true}
                placeholder="Valitse&hellip;"
                onChange={e => setMaakunnat(e.map(a => a.value))}
                options={[...Object.keys(Maakunnat).map(e => ({ value: e, label: Maakunnat[e] }))]}
              />
            </div>
            <div style={{ width: '200px' }}>
              <Text>Henkilöstömäärä</Text>
              <Select
                placeholder="Valitse&hellip;"
                onChange={e => setKoko(e.value)}
                options={[
                  { value: -1, label: 'Kaikki' },
                  { value: 0, label: '1-50' },
                  { value: 1, label: '51-100' },
                  { value: 2, label: '>100' },
                ]}
              />
            </div>
            <div style={{ width: '220px' }}>
              <Text>Omistuspohja</Text>
              <MultiSelect
                //@ts-ignore
                isMulti={true}
                allowSelectAll={true}
                placeholder="Valitse&hellip;"
                onChange={e => setOmistuspohja(e.map(a => a.value))}
                options={Object.keys(OmistusPohja).map(e => ({ value: e, label: OmistusPohja[e] }))}
              />
            </div>
            <Button aria-label="Hae" onClick={compareWithCustomGroup}>
              Hae
            </Button>
          </Stack>

          <Heading as="h2" size="sm" mb={2} mt={4}>
            Vertaile omiin kyselyihin
          </Heading>
          <Stack isInline align="flex-end">
            <div style={{ width: '200px' }}>
              <MultiSelect
                //@ts-ignore
                isMulti={false}
                placeholder="Valitse&hellip;"
                onChange={e => setKyselyid(e.value)}
                //@ts-ignore
                options={kyselyt?.map(e => ({ value: e.id, label: e.nimi }))}
              />
            </div>
            <Button aria-label="Hae" onClick={compareWithOwnSurveys}>
              Hae
            </Button>
          </Stack>
        </Box>
      </Box>
      <ChartLegend data={answerData.data.vastaus} />
      {answerData.data.vastaus.map((item: AnswerEntity) => (
        <Box
          key={item.index}
          w="100%"
          height="350px"
          mb={12}
          borderWidth="2px"
          borderRadius="xl"
          className="printSection"
        >
          <Box height="30%" padding={2}>
            <Text align="left" fontSize="md">
              {item.index}. {item.kysymys}
            </Text>
          </Box>
          <Box height="70%" padding={1}>
            <ResultChart data={[item]} key={item.index} />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default AdminResults
