import { Box } from '@chakra-ui/react'
import { ResponsiveContainer, BarChart, Bar, Legend } from 'recharts'

import { colors } from '../theme/colors'

export function ChartLegend(props) {
  if (props?.data[0]?.kaLuottamusmies === -1) {
    return (
      <Box className="footer" layerStyle="chart-legend">
        <ResponsiveContainer>
          <BarChart data={[]}>
            <Bar
              name="työnantajan edustajat"
              dataKey="kaTyonantaja"
              fill={colors.chart.kaTyonantaja}
            ></Bar>
            <Bar
              name="vertaisarvo työnantajan edustajat"
              dataKey="vaTyonantaja"
              fill={colors.chart.vaTyonantaja}
            ></Bar>
            <Bar
              name="työntekijät ja luottamushenkilöt"
              dataKey="kaTyontekija"
              fill={colors.chart.kaTyontekija}
            ></Bar>
            <Bar
              name="vertaisarvo työntekijät ja luottamushenkilöt"
              dataKey="vaTyontekija"
              fill={colors.chart.vaTyontekija}
            ></Bar>

            <Legend
              align="center"
              verticalAlign="top"
              iconType="circle"
              iconSize={24}
              fontSize={24}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    )
  } else {
    return (
      <Box className="footer" layerStyle="chart-legend">
        <ResponsiveContainer>
          <BarChart data={[]}>
            <Bar
              name="työnantajan edustajat"
              dataKey="kaTyonantaja"
              fill={colors.chart.kaTyonantaja}
            ></Bar>
            <Bar
              name="vertaisarvo työnantajan edustajat"
              dataKey="vaTyonantaja"
              fill={colors.chart.vaTyonantaja}
            ></Bar>
            <Bar name="työntekijät" dataKey="kaTyontekija" fill={colors.chart.kaTyontekija}></Bar>
            <Bar
              name="vertaisarvo työntekijät"
              dataKey="vaTyontekija"
              fill={colors.chart.vaTyontekija}
            ></Bar>
            <Bar
              name="luottamushenkilöt"
              dataKey="kaLuottamusmies"
              fill={colors.chart.kaLuottamusmies}
            ></Bar>
            <Bar
              name="vertaisarvo luottamushenkilöt"
              dataKey="vaLuottamusmies"
              fill={colors.chart.vaLuottamusmies}
            ></Bar>

            <Legend
              align="center"
              verticalAlign="top"
              iconType="circle"
              iconSize={24}
              fontSize={24}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    )
  }
}
