import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Stack, Heading, HStack, Badge, Link, Wrap } from '@chakra-ui/layout'
import { Modal, ModalContent, ModalCloseButton, ModalBody } from '@chakra-ui/modal'
import { Button, Text } from '@chakra-ui/react'
import { FormattedDate } from 'react-intl'
import { Label } from './Label'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { FaQrcode } from 'react-icons/fa'
import QRCode from 'react-qr-code'

const Card = props => {
  const entry = props.entry
  const { isOpen, onOpen, onClose } = useDisclosure()
  const history = useHistory()
  const user = props.user
  return (
    <Box key={entry.id} borderWidth="1px" borderRadius="lg">
      <Stack p={4} spacing={4}>
        <Heading as="h2" size="md">
          <HStack justifyContent="left" align="stretch">
            <Box flex="1">
              {entry.valmennuspaiva ? 'Valmennuspäivän kysely' : 'Vakiokysely'}
              <Badge lineHeight="100%" ml={2}>
                {entry.vastaukset.length}/{entry.maxVastaukset}
              </Badge>
            </Box>
            <Box>
              <Button aria-label="Open QR" onClick={onOpen}>
                <FaQrcode />
              </Button>

              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                  <ModalCloseButton />
                  <ModalBody m={10}>
                    <QRCode
                      style={{ margin: 'auto' }}
                      value={`${process.env.REACT_APP_OWN_URI}${entry.id}/${entry.salasana}`}
                    />
                    <Link as={RouterLink} target="_blank" to={`/${entry.id}/${entry.salasana}/qr`}>
                      {process.env.REACT_APP_OWN_URI}
                      {entry.id}/{entry.salasana}/qr
                    </Link>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          </HStack>
          <Text as="small" fontWeight="normal" opacity="75%">
            <FormattedDate value={new Date(entry.created_at)} /> &ndash;{' '}
            <FormattedDate value={new Date(entry.deadline)} />
          </Text>
        </Heading>

        <Box>
          <Label text="Vastaajille lähetettävä linkki kyselyyn:" />
          <Box p={1} borderStyle="dashed" borderWidth="1px" fontSize="sm">
            <Link as={RouterLink} target="_blank" to={`/${entry.id}`}>
              {process.env.REACT_APP_OWN_URI}
              {entry.id}
            </Link>
          </Box>
        </Box>
        <Box>
          <Label text="Vastaajille lähetettävä salasana kyselyyn:" />
          <Box p={1} borderStyle="dashed" borderWidth="1px" fontSize="sm">
            {entry.salasana}
          </Box>
        </Box>
      </Stack>
      <Stack spacing={4} px={4} py={3} borderTopWidth="1px">
        <Button
          aria-label="Edit survey"
          onClick={() =>
            history.push({
              pathname: '/admin/editsurvey',
              state: {
                id: entry.id,
                deadline: entry.deadline,
                maxAnswers: entry.maxVastaukset,
              },
            })
          }
          size="sm"
        >
          Muokkaa
        </Button>
        {entry.vastaukset.length > 5 && (
          <Button size="sm" onClick={() => history.push(`/admin/results/${entry.id}`, { entry })}>
            Vastaukset
          </Button>
        )}
        {entry.vastaukset.length > 5 && user?.isAdmin && (
          <Button
            aria-label="Report"
            onClick={() =>
              history.push({
                pathname: '/admin/report',
                state: {
                  id: entry.id,
                  entry,
                },
              })
            }
            size="sm"
          >
            Raportti
          </Button>
        )}
      </Stack>
    </Box>
  )
}

export default Card
