import { Text, VStack, Select, Button } from '@chakra-ui/react'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import { ChangeEvent, ReactElement, useEffect } from 'react'
import { Role, useRole } from '../contexts/RoleContext'
import LangSwitcher from '../components/LangSwitcher'

interface LocationState {
  displayLangSwitcher: boolean
}

function RoleSelection(props): ReactElement {
  const intl = useIntl()
  const { role, setRole } = useRole()
  const history = useHistory()
  const location = useLocation<LocationState>()

  const state = location.state as LocationState
  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    setRole(Role[event.target.value])
  }

  function handleKeyDown(e: { key: string }) {
    if (e.key == 'Enter') {
      history.push('/guide')
    }
  }
  useEffect(() => {
    //@ts-ignore
    setRole(Role[0])
    // eslint-disable-next-line
  }, [])

  return (
    <VStack spacing={8} pt={8}>
      {state.displayLangSwitcher ? <LangSwitcher setLanguage={props.setLanguage} /> : null}

      <Text>
        {intl.formatMessage({
          id: 'role',
          defaultMessage: 'Valitse roolisi',
        })}
      </Text>
      <Select onChange={e => handleChange(e)} onKeyDown={handleKeyDown} maxW="600px">
        <option value={Role.TYONTEKIJA}>
          {intl.formatMessage({
            id: 'employee',
            defaultMessage: 'Työntekijä',
          })}
        </option>
        <option value={Role.LUOTTAMUSMIES}>
          {intl.formatMessage({
            id: 'representative',
            defaultMessage: 'Luottamushenkilö',
          })}
        </option>
        <option value={Role.TYONANTAJAN_EDUSTAJA}>
          {intl.formatMessage({
            id: 'employer',
            defaultMessage: 'Työnantajan edustaja',
          })}
        </option>
      </Select>
      <Button aria-label="Seuraava" as={RouterLink} to="/guide" variant="primary">
        <FormattedMessage id="nextQuestion" defaultMessage="Seuraava" />
        &rarr;
      </Button>
    </VStack>
  )
}

export default RoleSelection
