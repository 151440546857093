import AdminLanding from './pages/AdminLanding'
import AdminLogin from './pages/AdminLogin'
import AdminResults from './pages/AdminResults'
import CreateSurvey from './pages/CreateSurvey'
import EditSurvey from './pages/EditSurvey'
import FinishSurvey from './pages/FinishSurvey'
import Landing from './pages/Landing'
import NotFound from './pages/NotFound'
import Report from './pages/Report'
import RoleSelection from './pages/RoleSelection'
import Guide from './pages/Guide'
import Survey from './pages/Survey'

import firebase from 'firebase'
import { Box, Spinner } from '@chakra-ui/react'
import { ReactElement, useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { CollectionContext } from './contexts/CollectionContext'
import { OrganizationContext } from './contexts/OrganizationContext'
import { RoleContext, Role } from './contexts/RoleContext'
import Navigation from './components/Navigation'
import AdminCreatePanel from './pages/AdminCreatePanel'
import { useAuthState } from './contexts/Firebase/Context'
import { useLazyQuery } from '@apollo/client'
import { ME, UserDataProps } from './queries/me'
import PrivateRoute from './components/PrivateRoute'
import fi from './locales/fi_FI.json'
import se from './locales/se_SE.json'
import en from './locales/en_EN.json'
import QrPage from './pages/QrPage'

export default function App(props): ReactElement {
  const [authentication, setAuthState] = useState({
    authenticated: false,
    initializing: true,
  })
  const [collection, setCollection] = useState<string>('677554f0-30fd-4de4-9373-8dbb36d9f11e')
  const [organization, setOrganization] = useState<string>('')
  const [role, setRole] = useState<Role>(Role.TYONTEKIJA)
  const [currentOrganization, setCurrentOrganization] = useState('')
  const [fetchCurrentOrganization, setFetchCurrentOrganization] = useState(false)
  const history = useHistory()
  const { user } = useAuthState()
  const [getMe, { data }] = useLazyQuery(ME)
  const [userData, setUserData] = useState<UserDataProps>()
  const [language, setLanguage] = useState<string>('')

  useEffect(() => {
    if (user) {
      getMe()
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (data?.me) {
      setUserData(data.me)
    }
  }, [data])

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(auth => {
        if (auth) {
          setAuthState({
            authenticated: true,
            initializing: false,
          })
        } else {
          setAuthState({
            authenticated: false,
            initializing: false,
          })
          setUserData(undefined)
          user == null
        }
      }),
    [setAuthState]
  )

  let translations
  let lang
  if (language === 'en_EN') {
    translations = en
    lang = 'en'
  } else if (language === 'se_SE') {
    translations = se
    lang = 'sv'
  } else {
    translations = fi
    lang = 'fi'
  }

  if (authentication.initializing) {
    return (
      <Box textAlign="center">
        <Spinner size="xl" />
      </Box>
    )
  }

  return (
    <IntlProvider locale={lang} defaultLocale="fi" messages={translations}>
      <RoleContext.Provider value={{ role, setRole }}>
        <Router>
          <OrganizationContext.Provider value={{ organization, setOrganization }}>
            <CollectionContext.Provider value={{ collection, setCollection }}>
              <Navigation
                fetchCurrentOrganization={fetchCurrentOrganization}
                user={user}
                authentication={authentication}
                currentOrganization={currentOrganization}
                setCurrentOrganization={setCurrentOrganization}
                userData={userData}
              />
              <Box p={3} textAlign="center" fontSize="xl">
                <Switch>
                  <PrivateRoute exact path="/admin/create">
                    <AdminCreatePanel />
                  </PrivateRoute>
                  <Route exact path="/thankyou">
                    <FinishSurvey />
                  </Route>
                  <Route exact path="/survey">
                    <Survey />
                  </Route>
                  <Route exact path="/guide">
                    <Guide />
                  </Route>
                  <Route exact path="/roleselection">
                    <RoleSelection setLanguage={setLanguage} />
                  </Route>
                  <PrivateRoute exact path="/admin/createsurvey">
                    <CreateSurvey />
                  </PrivateRoute>
                  <PrivateRoute exact path="/admin/editsurvey">
                    <EditSurvey />
                  </PrivateRoute>
                  <PrivateRoute exact path="/admin/results/:id">
                    <AdminResults />
                  </PrivateRoute>
                  <PrivateRoute exact path="/admin/report">
                    <Report />
                  </PrivateRoute>
                  <Route exact path="/login">
                    <AdminLogin />
                  </Route>
                  <PrivateRoute exact path="/admin">
                    <AdminLanding
                      currentOrganisation={setCurrentOrganization}
                      fetchCurrentOrganization={setFetchCurrentOrganization}
                    />
                  </PrivateRoute>
                  <Route path="/:id/:pw/qr">
                    <QrPage setLanguage={setLanguage} />
                  </Route>
                  <Route path="/:id/:pw">
                    <Landing setLanguage={setLanguage} />
                  </Route>
                  <Route path="/:id">
                    <Landing setLanguage={setLanguage} />
                  </Route>
                  <Route exact path="/">
                    <Landing setLanguage={setLanguage} />
                  </Route>
                  <Route component={NotFound} />
                </Switch>
              </Box>
            </CollectionContext.Provider>
          </OrganizationContext.Provider>
        </Router>
      </RoleContext.Provider>
    </IntlProvider>
  )
}
