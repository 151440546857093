import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Switch,
  useToast,
  Wrap,
} from '@chakra-ui/react'
import { useEffect, useState, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Label from './Label'
import Select from 'react-select'
import MultiSelect from './MultiSelect'
import Maakunnat from '../enums/Maakunnat'
import { OmistusPohja } from '../enums/Omistuspohja'
import { FaTrash } from 'react-icons/fa'
import { SINGLE_ORGANIZATION } from '../queries/organization'
import { useQuery } from '@apollo/client'
import { ME } from '../queries/me'

export function UpdateOrganizationModal({
  updateOrganizationId,
  updateOrganization,
  organisaatiot,
  userData,
  isOpen,
  onClose,
  refetch,
  createUser,
  userRefetch,
  removeUserFromOrganizationMutation,
  addUserToOrganization,
  fetchCurrentOrganization,
  currentOrganization = null,
  setCurrentOrganization = async (...props) => {},
}) {
  const [updateMaakunnat, setUpdateMaakunnat] = useState('')
  const [updateOmistuspohja, setupdateOmistuspohja] = useState('')
  const [updateNimi, setUpdateNimi] = useState('')
  const [updateKoko, setUpdateKoko] = useState(0)
  const [updateAktiivinen, setUpdateAktiivinen] = useState(true)
  const [addEmail, setAddEmail] = useState('')

  const { data } = useQuery(ME)
  const [me, setMe] = useState({ isAdmin: false })
  const [updateEmail, setUpdateEmail] = useState('')
  const [updateYtunnus, setUpdateYtunnus] = useState('')
  const [updateIsAdmin, setUpdateIsAdmin] = useState(false)
  const [updateIsActive, setUpdateIsActive] = useState(true)
  const [updateEtunimi, setUpdateEtunimi] = useState('')
  const [updateSukunimi, setUpdateSukunimi] = useState('')
  const [updateOrganisaatiot, setUpdateOrganisaatiot] = useState<any[]>([])

  const [organizationUsers, setOrganizationUsers] = useState([])

  const organizationData = useQuery(SINGLE_ORGANIZATION, {
    variables: { organizationId: updateOrganizationId.id || '' },
  })
  const toast = useToast()
  const intl = useIntl()
  useEffect(() => {
    if (data) {
      setMe(data.me)
    }
  }, [data])

  useEffect(() => {
    if (updateOrganizationId.type === 'organization') {
      const selected: any =
        organisaatiot.find((e: any) => e.id === updateOrganizationId.id) || currentOrganization
      if (selected) {
        setUpdateMaakunnat(selected.maakunta)
        setUpdateKoko(selected.koko)
        setUpdateNimi(selected.nimi)
        setupdateOmistuspohja(selected.omistuspohja)
        setUpdateAktiivinen(selected.active)
        setOrganizationUsers(selected.users)
        setUpdateYtunnus(selected.ytunnus)
      }
    }
    if (updateOrganizationId.type === 'user') {
      const selected: any = userData.users.find((e: any) => e.id === updateOrganizationId.id)
      if (selected) {
        setUpdateEmail(selected.email)
        setUpdateIsAdmin(selected.isAdmin)
        setUpdateEtunimi(selected.etunimi)
        setUpdateSukunimi(selected.sukunimi)
        setUpdateIsActive(selected.active)
        setUpdateOrganisaatiot(selected.organisaatiot)
      }
    }
  }, [updateOrganizationId, organisaatiot, userData, currentOrganization])

  useEffect(() => {
    if (organizationData.data) {
      setCurrentOrganization(organizationData.data.organization)
    }
  }, [organizationData.data])
  const sendUpdateOrganization = useCallback(async () => {
    try {
      const testi = await updateOrganization({
        variables: {
          id: updateOrganizationId.id,
          //@ts-ignore
          nimi: updateNimi,
          maakunta: updateMaakunnat,
          koko: updateKoko,
          omistuspohja: updateOmistuspohja,
          active: updateAktiivinen,
          ytunnus: updateYtunnus,
        },
      })

      !me.isAdmin && (await organizationData.refetch())
      toast({
        title: intl.formatMessage({
          id: 'update_organization_success',
          defaultMessage: 'Organisaatio päivitetty!',
        }),
        status: 'success',
        duration: 5000,
      })
      me.isAdmin && (await refetch())
    } catch (e) {
      toast({
        title: intl.formatMessage({
          id: 'create_user_fail',
          defaultMessage: 'Jokin meni vikaan.',
        }),
        status: 'error',
        duration: 5000,
      })
    }
  }, [
    updateAktiivinen,
    updateNimi,
    updateMaakunnat,
    updateKoko,
    updateOmistuspohja,
    updateYtunnus,
    fetchCurrentOrganization,
    organizationData,
  ])

  /*const sendUpdateOrganizationSorting = async () => {
      try {
      await updateOrganizationSorting({
          
      })
      } catch (e) {}
  }*/

  const sendUpdateUser = async () => {
    try {
      await createUser({
        variables: {
          //@ts-ignore
          email: updateEmail,
          isAdmin: updateIsAdmin,
          etunimi: updateEtunimi,
          sukunimi: updateSukunimi,
          isActive: updateIsActive,
        },
      })
      me.isAdmin && (await userRefetch())
      toast({
        title: intl.formatMessage({
          id: 'create_user_success',
          defaultMessage: 'Käyttäjä päivitetty!',
        }),
        status: 'success',
        duration: 5000,
      })
    } catch (e) {
      toast({
        title: intl.formatMessage({
          id: 'create_user_fail',
          defaultMessage: 'Jokin meni vikaan.',
        }),
        status: 'error',
        duration: 5000,
      })
    }
  }

  const removeUserFromOrganization = async (organization, email) => {
    try {
      await removeUserFromOrganizationMutation({
        variables: { organizationId: organization, email },
      })
      me.isAdmin && (await refetch())
    } catch (e) {}
  }
  const sendAddUserToOrganization = async () => {
    try {
      await addUserToOrganization({
        variables: {
          //@ts-ignore
          email: addEmail,
          organizationId: updateOrganizationId.id,
        },
      })
      me.isAdmin && (await refetch())
      toast({
        title: intl.formatMessage({
          id: 'add_user_to_organization_success',
          defaultMessage: 'Käyttäjä lisätty!',
        }),
        status: 'success',
        duration: 5000,
      })
    } catch (e) {
      toast({
        title: intl.formatMessage({
          id: 'create_user_fail',
          defaultMessage: 'Jokin meni vikaan.',
        }),
        status: 'error',
        duration: 5000,
      })
    }
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage id="organization_info" defaultMessage="Tiedot" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {updateOrganizationId.type === 'organization' && (
              <>
                <Stack p={4} spacing={4}>
                  <Stack p={0} spacing={4}>
                    {me.isAdmin && (
                      <Box>
                        <Label
                          text={intl.formatMessage({
                            id: 'name',
                            defaultMessage: 'Nimi',
                          })}
                        />
                        <Input value={updateNimi} onChange={e => setUpdateNimi(e.target.value)} />
                      </Box>
                    )}
                    <Box>
                      <Label
                        text={intl.formatMessage({
                          id: 'maakunta',
                          defaultMessage: 'Maakunta',
                        })}
                      />

                      <MultiSelect
                        //@ts-ignore
                        isMulti={false}
                        value={{
                          label: Maakunnat[updateMaakunnat],
                          value: updateMaakunnat,
                        }}
                        placeholder="Valitse&hellip;"
                        onChange={e => setUpdateMaakunnat(e.value)}
                        options={[
                          ...Object.keys(Maakunnat).map(e => ({ value: e, label: Maakunnat[e] })),
                        ]}
                      />
                    </Box>
                    <Box>
                      <Label
                        text={intl.formatMessage({
                          id: 'omistuspohja',
                          defaultMessage: 'Omistuspohja',
                        })}
                      />

                      <MultiSelect
                        //@ts-ignore
                        isMulti={false}
                        value={{
                          label: OmistusPohja[updateOmistuspohja],
                          value: updateOmistuspohja,
                        }}
                        placeholder="Valitse&hellip;"
                        onChange={e => setupdateOmistuspohja(e.value)}
                        options={[
                          ...Object.keys(OmistusPohja).map(e => ({
                            value: e,
                            label: OmistusPohja[e],
                          })),
                        ]}
                      />
                    </Box>
                    <Box>
                      <Label
                        text={intl.formatMessage({
                          id: 'koko',
                          defaultMessage: 'Henkilöstömäärä',
                        })}
                      />

                      <NumberInput
                        w={24}
                        size="sm"
                        value={updateKoko}
                        min={0}
                        onChange={maxAnswersString => setUpdateKoko(parseInt(maxAnswersString))}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Box>
                    <Box>
                      {me.isAdmin && (
                        <>
                          <Label
                            text={intl.formatMessage({ id: 'ytunnus', defaultMessage: 'Y-tunnus' })}
                          />
                          <Input
                            value={updateYtunnus}
                            onChange={e => setUpdateYtunnus(e.target.value)}
                          />
                        </>
                      )}
                    </Box>
                    <Box>
                      {me.isAdmin && (
                        <>
                          <Label
                            text={intl.formatMessage({
                              id: 'organization_active',
                              defaultMessage: 'Aktiivinen',
                            })}
                          />
                          <Switch
                            colorScheme="brand.secondary"
                            size="lg"
                            isChecked={updateAktiivinen}
                            onChange={e => setUpdateAktiivinen(e.target.checked)}
                          />
                        </>
                      )}
                    </Box>
                  </Stack>
                </Stack>
                <ModalFooter>
                  <Button
                    aria-label="Päivitä"
                    variant="primary"
                    onClick={async () => {
                      await sendUpdateOrganization()
                      me.isAdmin && (await userRefetch())
                      onClose()
                    }}
                  >
                    {<FormattedMessage id="save" defaultMessage="Tallenna" />}
                  </Button>
                  <Button aria-label="Peru" variant="secondary" ml={3} mr={3} onClick={onClose}>
                    Peru
                  </Button>
                </ModalFooter>
                <Wrap spacing={4} px={4} py={3} borderTopWidth="1px"></Wrap>

                <Stack p={4} spacing={4}>
                  <Heading as="h3" size="sm">
                    <FormattedMessage id="organization_users" defaultMessage="Käyttäjät" />
                  </Heading>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'email',
                        defaultMessage: 'Sähköposti',
                      })}
                    />
                    <Flex>
                      <Input onChange={e => setAddEmail(e.target.value)} flex="1 1 auto" mr="1" />
                      <Button
                        aria-label="Lisää"
                        variant="primary"
                        onClick={sendAddUserToOrganization}
                      >
                        <FormattedMessage id="addUser" defaultMessage="Lisää" />
                      </Button>
                    </Flex>
                  </Box>
                  <Box>
                    <List spacing={3}>
                      {organizationUsers.map((user: any) => (
                        <ListItem key={user.id} fontSize="sm">
                          <IconButton
                            onClick={() =>
                              removeUserFromOrganization(updateOrganizationId.id, user.email)
                            }
                            aria-label="delete"
                            icon={<FaTrash />}
                          />
                          {`${user.etunimi} ${user.sukunimi} `}
                          <Link href={`mailto:${user.email}`}>{user.email}</Link>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Stack>
              </>
            )}
            {updateOrganizationId.type === 'user' && (
              <>
                <Stack p={4} spacing={4}>
                  <Heading as="h2" size="md">
                    <FormattedMessage id="create_user" defaultMessage="Päivitä Käyttäjä" />
                  </Heading>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'email',
                        defaultMessage: 'Sähköposti',
                      })}
                    />
                    <Input
                      value={updateEmail}
                      onChange={e => setUpdateEmail(e.target.value)}
                      type="email"
                    />
                  </Box>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'firstname',
                        defaultMessage: 'Etunimi',
                      })}
                    />
                    <Input value={updateEtunimi} onChange={e => setUpdateEtunimi(e.target.value)} />
                  </Box>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'surname',
                        defaultMessage: 'Sukunimi',
                      })}
                    />
                    <Input
                      value={updateSukunimi}
                      onChange={e => setUpdateSukunimi(e.target.value)}
                    />
                  </Box>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'create_admin',
                        defaultMessage: 'Onko admin?',
                      })}
                    />

                    <Select
                      w={48}
                      onChange={e => setUpdateIsAdmin(e.value)}
                      defaultValue={{ value: false, label: 'Ei' }}
                      //@ts-ignore
                      options={[
                        { value: false, label: 'Ei' },
                        { value: true, label: 'Kyllä' },
                      ]}
                    />
                  </Box>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'isActive',
                        defaultMessage: 'Aktiivinen?',
                      })}
                    />

                    <Select
                      w={48}
                      onChange={e => setUpdateIsActive(e.value)}
                      defaultValue={{ value: true, label: 'Kyllä' }}
                      //@ts-ignore
                      options={[
                        { value: false, label: 'Ei' },
                        { value: true, label: 'Kyllä' },
                      ]}
                    />
                  </Box>
                  <Box>
                    <Heading as="h3">Organisaatiot</Heading>
                    <Label text="Lisää organisaatioon" for="updateOrganisation" />
                    <Select
                      id="updateOrganisation"
                      options={organisaatiot.map(e => ({ value: e.id, label: e.nimi }))}
                      onChange={async e => {
                        await addUserToOrganization({
                          variables: { email: updateEmail, organizationId: e.value },
                        })
                        ;(await me.isAdmin) && (await userRefetch())()
                      }}
                    />
                    <List mt={3} spacing={3}>
                      {updateOrganisaatiot.map((organization: any) => (
                        <ListItem key={organization.id} fontSize="sm">
                          <IconButton
                            mr={3}
                            onClick={async () => {
                              await removeUserFromOrganization(organization.id, updateEmail)
                              ;(await me.isAdmin) && (await userRefetch())()
                            }}
                            aria-label="delete"
                            icon={<FaTrash />}
                          />
                          {`${organization.nimi} `}
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Stack>

                <ModalFooter>
                  <Button
                    aria-label="Päivitä"
                    variant="primary"
                    onClick={() => {
                      sendUpdateUser()
                      onClose()
                    }}
                  >
                    {<FormattedMessage id="save" defaultMessage="Tallenna" />}
                  </Button>
                  <Button aria-label="Peru" variant="secondary" ml={3} mr={3} onClick={onClose}>
                    Peru
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
