import { Button, useToast } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
export function QueryButton({
  lastQuestion,
  sendAnswers,
  handleClick,
  value,
  loading,
  index,
  handleBackClick,
}): JSX.Element {
  const intl = useIntl()
  const toast = useToast()

  function handleEmpty() {
    toast({
      title: intl.formatMessage({
        id: 'answer_required',
        defaultMessage: 'Vastaus on pakollinen',
      }),
      status: 'error',
      duration: 2000,
    })
  }

  const backButton = index > 0 && (
    <Button variant="secondary" onClick={handleBackClick} mr={2} disabled={loading}>
      {intl.formatMessage({
        id: 'back',
        defaultMessage: 'Takaisin',
      })}
    </Button>
  )
  if (lastQuestion) {
    return (
      <Button
        aria-label="Tallenna vastaukset"
        isLoading={loading}
        onClick={sendAnswers}
        colorScheme="brand.success"
      >
        {intl.formatMessage({
          id: 'send',
          defaultMessage: 'Tallenna vastaukset',
        })}
      </Button>
    )
  }
  if (value === 0) {
    return (
      <Button aria-label="Seuraava" isLoading={loading} onClick={handleEmpty} variant="primary">
        {intl.formatMessage({
          id: 'nextQuestion',
          defaultMessage: 'Seuraava',
        })}
        &rarr;
      </Button>
    )
  }
  return (
    <Button aria-label="Seuraava" isLoading={loading} onClick={handleClick} variant="primary">
      {intl.formatMessage({
        id: 'nextQuestion',
        defaultMessage: 'Seuraava',
      })}
      &rarr;
    </Button>
  )
}

export default QueryButton
