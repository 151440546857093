import { createContext, useContext } from 'react'

export enum Role {
  TYONTEKIJA,
  LUOTTAMUSMIES,
  TYONANTAJAN_EDUSTAJA,
}

export type RoleContextType = {
  // prettier-ignore
  role: Role,
  setRole: (Role: Role) => void
}

export const RoleContext = createContext<RoleContextType>({
  role: Role.TYONTEKIJA,
  setRole: role => console.warn('no role provided'),
})

export const useRole = () => useContext(RoleContext)
