import { ReactElement, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Heading,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  Stack,
  Wrap,
  useToast,
} from '@chakra-ui/react'
import Label from '../components/Label'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_SURVEY } from '../mutations/create_survey'
import { FormattedMessage, useIntl } from 'react-intl'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import fi from 'date-fns/locale/fi'
import { useOrganization } from '../contexts/OrganizationContext'
import { ALL_COLLECTIONS } from '../queries/all_collections'
import Select from 'react-select'

registerLocale('fi', fi)
setDefaultLocale('fi')

function CreateSurvey(): ReactElement {
  // const { collection } = useCollection()
  const { organization } = useOrganization()

  const [collection, setCollection] = useState(null)
  const [valmennuspaiva, setValmennuspaiva] = useState<boolean>(false)
  const [maxVastaukset, setMaxVastaukset] = useState<number>(15)
  const [deadline, setDeadline] = useState<Date>(new Date())

  const [allCollections, setAllCollections] = useState([])
  const history = useHistory()
  const toast = useToast()
  const intl = useIntl()

  const [createSurvey, { loading: mutationLoading }] = useMutation(CREATE_SURVEY)
  const getCollections = useQuery(ALL_COLLECTIONS)

  function handleDate(date) {
    setDeadline(new Date(date))
  }

  const handleHardCodedSurveyType = isTrainingDay => {
    const vakiokysely: any = allCollections.find((c: any) => c.nimi === 'vakio')

    if (vakiokysely) {
      setCollection(vakiokysely.id)
      setValmennuspaiva(isTrainingDay)
    }
  }

  useEffect(() => {
    if (getCollections.data) {
      setAllCollections(getCollections.data.collections)
    }
  }, [getCollections])
  const send = async () => {
    try {
      await createSurvey({
        variables: {
          collectionId: collection,
          organizationId: organization,
          deadline: deadline,
          valmennuspaiva: valmennuspaiva,
          maxVastaukset: maxVastaukset,
        },
      })
    } catch (e) {
      console.log(JSON.stringify(e, null, 2))
    }
    history.push('/admin')
    history.go(0)
  }

  return (
    <Box textAlign="left">
      <Heading as="h1" mb={4}>
        <FormattedMessage id="createSurvey" defaultMessage="Luo uusi kysely" />
      </Heading>
      <Box borderWidth="1px" borderRadius="lg" fontSize="sm" maxW="500px">
        <Stack p={4} spacing={4}>
          {allCollections.length > 1 ? (
            <Box>
              <Label
                text={intl.formatMessage({
                  id: 'survey_type',
                  defaultMessage: 'Kyselyn tyyppi',
                })}
              />
              <Select
                w={48}
                placeholder="Valitse"
                onChange={e => setCollection(e.value)}
                //@ts-ignore
                options={allCollections.map(e => ({ value: e.id, label: e.nimi }))}
              />
            </Box>
          ) : (
            <Box>
              <Label
                text={intl.formatMessage({
                  id: 'survey_type',
                  defaultMessage: 'Kyselyn tyyppi',
                })}
              />
              <Select
                w={48}
                placeholder="Valitse"
                onChange={e => handleHardCodedSurveyType(e.value)}
                //@ts-ignore
                options={[
                  { value: false, label: 'Vakio' },
                  { value: true, label: 'Valmennuspäivä' },
                ]}
              />
            </Box>
          )}
          {allCollections.length > 1 ? (
            <Box>
              <Label
                text={intl.formatMessage({
                  id: 'valmennuspaiva',
                  defaultMessage: 'Valmennuspäivä?',
                })}
              />
              <Select
                w={48}
                placeholder="Valitse"
                defaultValue={{ value: false, label: 'Ei' }}
                onChange={e => setValmennuspaiva(e.value)}
                //@ts-ignore
                options={[
                  { value: false, label: 'Ei' },
                  { value: true, label: 'Kyllä' },
                ]}
              />
            </Box>
          ) : null}
          <Box>
            <Label
              text={intl.formatMessage({
                id: 'max_number_answers',
                defaultMessage: 'Vastausten maksimimäärä',
              })}
            />
            <NumberInput
              w={24}
              size="sm"
              defaultValue={maxVastaukset}
              min={0}
              onChange={maxAnswersString => setMaxVastaukset(parseInt(maxAnswersString))}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box>
            <Label
              text={intl.formatMessage({
                id: 'survey_open_until',
                defaultMessage: 'Viimeinen vastauspäivä',
              })}
            />
            <Box borderWidth="1px" borderRadius="sm" p={2} w={44}>
              <DatePicker
                locale="fi"
                dateFormat="d.M.yyyy"
                selected={deadline}
                onChange={handleDate}
              />
            </Box>
          </Box>
        </Stack>
        <Wrap spacing={4} px={4} py={3} borderTopWidth="1px">
          <Button
            aria-label="Luo kysely"
            variant="primary"
            isLoading={mutationLoading}
            onClick={send}
          >
            <FormattedMessage id="create" defaultMessage="Luo kysely" />
          </Button>
        </Wrap>
      </Box>
    </Box>
  )
}

export default CreateSurvey
