// TEOLLISUUSLIITTO THEME
import { theme as baseTheme } from '@chakra-ui/react'

export const colors = {
  brand: {
    black: baseTheme.colors.black,
    white: baseTheme.colors.white,
    gray: baseTheme.colors.gray,
    primary: {
      50: '#E6FFFA',
      100: '#B2F5EA',
      200: '#81E6D9',
      300: '#4FD1C5',
      400: '#38B2AC',
      500: '#319795',
      600: '#2C7A7B',
      700: '#285E61',
      800: '#234E52',
      900: '#1D4044',
    },
    secondary: {
      50: '#f2f2f2',
      100: '#d9d9d9',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#262626',
      900: '#0d0d0d',
    },
    success: baseTheme.colors.green,
  },
  chart: {
    kaTyonantaja: '#e60000',
    vaTyonantaja: '#500808',
    kaTyontekija: '#5b5857',
    vaTyontekija: '#8e8a89',
    kaLuottamusmies: '#30b6aa',
    vaLuottamusmies: '#6edad0',
  },
}
