import { gql } from '@apollo/client'

export const ALL_PATTERISTO_FOR_ORGANIZATION = gql`
  query getAllPatteristoForOrganization($organizationId: String!) {
    patteristot(id: $organizationId) {
      id
      created_at
      deadline
      salasana
      vastaukset {
        id
      }
      valmennuspaiva
      maxVastaukset
    }
  }
`
