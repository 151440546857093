import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react'

export function ResultTable(props) {
  const data = props.data

  if (data.kaLuottamusmies === -1) {
    return (
      <Box w="750px">
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th></Th>
              <Th textAlign="right">Erittäin huonosti</Th>
              <Th textAlign="right">Huonosti</Th>
              <Th textAlign="right">En osaa sanoa</Th>
              <Th textAlign="right">Hyvin</Th>
              <Th textAlign="right">Erittäin hyvin</Th>
              <Th textAlign="right">Yht</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td isNumeric>Työnantajan edustaja</Td>
              <Td isNumeric>{data.reallyBadTyonantaja}</Td>
              <Td isNumeric>{data.badTyonantaja}</Td>
              <Td isNumeric>{data.cantTellTyonantaja}</Td>
              <Td isNumeric>{data.goodTyonantaja}</Td>
              <Td isNumeric>{data.reallyGoodTyonantaja}</Td>
              <Td isNumeric>
                {data.reallyBadTyonantaja +
                  data.badTyonantaja +
                  data.goodTyonantaja +
                  data.reallyGoodTyonantaja}
              </Td>
            </Tr>
            <Tr>
              <Td isNumeric>Luottamushenkilö tai työntekijä</Td>
              <Td isNumeric>{data.reallyBadTyontekija}</Td>
              <Td isNumeric>{data.badTyontekija}</Td>
              <Td isNumeric>{data.cantTellTyontekija}</Td>
              <Td isNumeric>{data.goodTyontekija}</Td>
              <Td isNumeric>{data.reallyGoodTyontekija}</Td>
              <Td isNumeric>
                {data.reallyBadTyontekija +
                  data.badTyontekija +
                  data.goodTyontekija +
                  data.reallyGoodTyontekija}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    )
  } else {
    return (
      <Box>
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              <Th></Th>
              <Th textAlign="right">Erittäin huonosti</Th>
              <Th textAlign="right">Huonosti</Th>
              <Th textAlign="right">En osaa sanoa</Th>
              <Th textAlign="right">Hyvin</Th>
              <Th textAlign="right">Erittäin hyvin</Th>
              <Th textAlign="right">Yht</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td isNumeric>Työnantajan edustaja</Td>
              <Td isNumeric>{data.reallyBadTyonantaja}</Td>
              <Td isNumeric>{data.badTyonantaja}</Td>
              <Td isNumeric>{data.cantTellTyonantaja}</Td>
              <Td isNumeric>{data.goodTyonantaja}</Td>
              <Td isNumeric>{data.reallyGoodTyonantaja}</Td>
              <Td isNumeric>
                {data.reallyBadTyonantaja +
                  data.badTyonantaja +
                  data.goodTyonantaja +
                  data.reallyGoodTyonantaja}
              </Td>
            </Tr>
            <Tr>
              <Td isNumeric>Työntekijä</Td>
              <Td isNumeric>{data.reallyBadTyontekija}</Td>
              <Td isNumeric>{data.badTyontekija}</Td>
              <Td isNumeric>{data.cantTellTyontekija}</Td>
              <Td isNumeric>{data.goodTyontekija}</Td>
              <Td isNumeric>{data.reallyGoodTyontekija}</Td>
              <Td isNumeric>
                {data.reallyBadTyontekija +
                  data.badTyontekija +
                  data.goodTyontekija +
                  data.reallyGoodTyontekija}
              </Td>
            </Tr>
            <Tr>
              <Td isNumeric>Luottamushenkilö</Td>
              <Td isNumeric>{data.reallyBadLuottamusmies}</Td>
              <Td isNumeric>{data.badLuottamusmies}</Td>
              <Td isNumeric>{data.cantTellLuottamusmies}</Td>
              <Td isNumeric>{data.goodLuottamusmies}</Td>
              <Td isNumeric>{data.reallyGoodLuottamusmies}</Td>
              <Td isNumeric>
                {data.reallyBadLuottamusmies +
                  data.badLuottamusmies +
                  data.goodLuottamusmies +
                  data.reallyGoodLuottamusmies}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    )
  }
}
