/* eslint-disable indent */
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  LabelList,
  ErrorBar,
  ReferenceLine,
  CartesianGrid,
  Tooltip,
} from 'recharts'

import { colors } from '../theme/colors'
import '../theme/reportAndResultStyles.css'
const renderCustomizedLabel = props => {
  const { x, y, width, height, value, offset } = props

  return (
    //   x={x + 10}
    //   y={y + height - offset}
    //   height={height}
    //   width={width}
    //   textAnchor="start"
    //   fontSize={height * 0.8}
    //   fill={colors.brand.white}
    // ></text>
    <text
      x={x}
      y={y + height - offset}
      height={height}
      width={width}
      dy={-4}
      fontSize={height * 0.7}
      fill={colors.brand.white}
      textAnchor="start"
    >
      {value}
    </text>
  )
}

const formatXAxis = (value: number) => {
  switch (value) {
    case 1:
      return '1: Täysin eri mieltä'
    case 2:
      return '2: Jokseenkin eri mieltä'
    case 3:
      return '3: Jokseenkin samaa mieltä'
    case 4:
      return '4: Täysin samaa mieltä'
    default:
      return ''
  }
}
export function ResultChart(props) {
  let data = props.data
  data = data.map(d => ({
    ...d,
    kaLuottamusmies: d.kaLuottamusmies === 1 ? 1.1 : d.kaLuottamusmies,
    kaTyonantaja: d.kaTyonantaja === 1 ? 1.1 : d.kaTyonantaja,
    kaTyontekija: d.kaTyontekija === 1 ? 1.1 : d.kaTyontekija,
    khLuottamusmies: d.khLuottamusmies === 1 ? 1.1 : d.khLuottamusmies,
    khTyonantaja: d.khTyonantaja === 1 ? 1.1 : d.khTyonantaja,
    khTyontekija: d.khTyontekija === 1 ? 1.1 : d.khTyontekija,
    vaLuottamusmies: d.vaLuottamusmies === 1 ? 1.1 : d.vaLuottamusmies,
    vaTyonantaja: d.vaTyonantaja === 1 ? 1.1 : d.vaTyonantaja,
    vaTyontekija: d.vaTyontekija === 1 ? 1.1 : d.vaTyontekija,
    vhLuottamusmies: d.vhLuottamusmies === 1 ? 1.1 : d.vhLuottamusmies,
    vhTyonantaja: d.vhTyonantaja === 1 ? 1.1 : d.vhTyonantaja,
    vhTyontekija: d.vhTyontekija === 1 ? 1.1 : d.vhTyontekija,
  }))

  const barTooltipStyle = {
    outerWidth: 10,
    backgroundColor: 'white',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '0.5em',
    padding: '10px',
    fontFamily: 'helvetica, sans-serif',
    fontSize: '16px',
  }

  let barTooltip = ''
  const BarTooltip = ({ active, payload }) => {
    if (!active || !barTooltip) return null
    for (const bar of payload)
      if (bar.dataKey === barTooltip)
        return (
          <div className="barTooltip" style={barTooltipStyle}>
            <p style={{ color: colors.chart[barTooltip] }}>{bar.name}</p>
            <p>{bar.value.toFixed(2)}</p>
          </div>
        )
    return null
  }
  if (data[0].kaLuottamusmies === -1) {
    return (
      <ResponsiveContainer>
        <BarChart data={data} layout="vertical" margin={{ left: 200, right: 200 }}>
          <XAxis
            domain={[1, 4]}
            allowDataOverflow={true}
            type="number"
            tickCount={4}
            tickFormatter={formatXAxis}
          />
          <YAxis type="category" dataKey="kysymys" tick={false} width={1} />
          <Tooltip
            content={<BarTooltip active={undefined} payload={undefined} />}
            cursor={false}
            position={{}}
            allowEscapeViewBox={{ x: true, y: true }}
          />
          <ReferenceLine x={2.5} stroke="black" />
          <CartesianGrid strokeDasharray="1 1" />
          <Bar
            name="Työnantajan edustajat"
            dataKey="kaTyonantaja"
            fill={colors.chart.kaTyonantaja}
            onMouseOver={() => (barTooltip = 'kaTyonantaja')}
            onMouseLeave={() => (barTooltip = '')}
          >
            <LabelList
              dataKey="kaTyonantaja"
              position="insideLeft"
              name="keskiarvo työnantaja"
              content={renderCustomizedLabel}
            />
            <ErrorBar name="keskihajonta työnantaja" dataKey="khTyonantaja" />
          </Bar>
          <Bar
            name="Vertaisarvo työnantajan edustajat"
            dataKey="vaTyonantaja"
            fill={colors.chart.vaTyonantaja}
            onMouseOver={() => (barTooltip = 'vaTyonantaja')}
            onMouseLeave={() => (barTooltip = '')}
          >
            <LabelList
              dataKey="vaTyonantaja"
              position="insideLeft"
              name="vertaisarvo työnantaja"
              content={renderCustomizedLabel}
            />
            <ErrorBar name="keskihajonta työnantaja" dataKey="vhTyonantaja" />
          </Bar>
          <Bar
            name="Työntekijät"
            dataKey="kaTyontekija"
            fill={colors.chart.kaTyontekija}
            onMouseOver={() => (barTooltip = 'kaTyontekija')}
            onMouseLeave={() => (barTooltip = '')}
          >
            <LabelList
              dataKey="kaTyontekija"
              position="insideLeft"
              content={renderCustomizedLabel}
            />
            <ErrorBar name="keskihajonta työntekijä" dataKey="khTyontekija" />
          </Bar>
          <Bar
            name="Vertaisarvo työntekijät"
            dataKey="vaTyontekija"
            fill={colors.chart.vaTyontekija}
            onMouseOver={() => (barTooltip = 'vaTyontekija')}
            onMouseLeave={() => (barTooltip = '')}
          >
            <LabelList
              dataKey="vaTyontekija"
              position="insideLeft"
              name="vertaisarvo työntekijä"
              content={renderCustomizedLabel}
            />
            <ErrorBar name="keskihajonta työntekijä" dataKey="vhTyontekija" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    )
  } else {
    return (
      <ResponsiveContainer>
        <BarChart data={data} layout="vertical" margin={{ left: 200, right: 200 }}>
          <XAxis
            domain={[1, 4]}
            allowDataOverflow={true}
            type="number"
            tickCount={4}
            tickFormatter={formatXAxis}
          />
          <YAxis type="category" dataKey="kysymys" tick={false} width={1} />
          <ReferenceLine x={2.5} stroke="black" />
          <CartesianGrid strokeDasharray="1 1" />
          <Tooltip cursor={false} content={<BarTooltip active={undefined} payload={undefined} />} />
          <Bar
            name="Työnantajan edustajat"
            dataKey="kaTyonantaja"
            fill={colors.chart.kaTyonantaja}
            onMouseOver={() => (barTooltip = 'kaTyonantaja')}
            onMouseLeave={() => (barTooltip = '')}
          >
            <LabelList
              dataKey="kaTyonantaja"
              position="insideLeft"
              name="Keskiarvo työnantajan edustajat"
              content={renderCustomizedLabel}
            />
            <ErrorBar name="keskihajonta työnantaja" dataKey="khTyonantaja" />
          </Bar>
          <Bar
            name="Vertaisarvo työnantajan edustajat"
            dataKey="vaTyonantaja"
            fill={colors.chart.vaTyonantaja}
            onMouseOver={() => (barTooltip = 'vaTyonantaja')}
            onMouseLeave={() => (barTooltip = '')}
          >
            <Tooltip />
            <LabelList
              dataKey="vaTyonantaja"
              position="insideLeft"
              name="vertaisarvo työnantaja"
              content={renderCustomizedLabel}
            />
            <ErrorBar name="keskihajonta työnantaja" dataKey="vhTyonantaja" />
          </Bar>
          <Bar
            name="Työntekijät"
            dataKey="kaTyontekija"
            fill={colors.chart.kaTyontekija}
            onMouseOver={() => (barTooltip = 'kaTyontekija')}
            onMouseLeave={() => (barTooltip = '')}
          >
            <Tooltip />
            <LabelList
              dataKey="kaTyontekija"
              position="insideLeft"
              name="Keskiarvo työntekijät"
              content={renderCustomizedLabel}
            />
            <ErrorBar name="keskihajonta työntekijä" dataKey="khTyontekija" />
          </Bar>
          <Bar
            name="Vertaisarvo työntekijät"
            dataKey="vaTyontekija"
            fill={colors.chart.vaTyontekija}
            onMouseOver={() => (barTooltip = 'vaTyontekija')}
            onMouseLeave={() => (barTooltip = '')}
          >
            <LabelList
              dataKey="vaTyontekija"
              position="insideLeft"
              name="vertaisarvo työntekijä"
              content={renderCustomizedLabel}
            />
            <ErrorBar name="keskihajonta työntekijä" dataKey="vhTyontekija" />
          </Bar>
          <Bar
            name="Luottamushenkilöt"
            dataKey="kaLuottamusmies"
            fill={colors.chart.kaLuottamusmies}
            onMouseOver={() => (barTooltip = 'kaLuottamusmies')}
            onMouseLeave={() => (barTooltip = '')}
          >
            <LabelList
              dataKey="kaLuottamusmies"
              position="insideLeft"
              name="Keskiarvo Luottamushenkilöt"
              content={renderCustomizedLabel}
            />
            <ErrorBar name="keskihajonta luottamusmies" dataKey="khLuottamusmies" />
          </Bar>
          <Bar
            name="Vertaisarvo luottamushenkilöt"
            dataKey="vaLuottamusmies"
            fill={colors.chart.vaLuottamusmies}
            onMouseOver={() => (barTooltip = 'vaLuottamusmies')}
            onMouseLeave={() => (barTooltip = '')}
          >
            <LabelList
              dataKey="vaLuottamusmies"
              position="insideLeft"
              name="vertaisarvo luottamusmies"
              content={renderCustomizedLabel}
            />
            <ErrorBar name="keskihajonta luottamusmies" dataKey="vhLuottamusmies" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    )
  }
}
