import { gql } from '@apollo/client'

export const ALL_QUESTIONS = gql`
  query getAllQuestions($patteristoId: String!, $language: String, $role: Roolit!) {
    collection(data: { id: $patteristoId, language: $language, role: $role }) {
      id
      nimi
      versio
      kysymykset {
        id
        index
        otsikko
        aliotsikko
        kysymys
        kieli
        valmennuspaiva
        sallittu_rooleille {
          id
          role
        }
        sallittu_kyselyissa
      }
    }
  }
`
