import { gql } from '@apollo/client'
export interface OrganizationProps {
  id: string
  nimi: string
}
export interface UserDataProps {
  id: string
  email: string
  etunimi: string
  sukunimi: string
  rooli: any
  organisaatiot: [OrganizationProps]
}

export const ME = gql`
  query me {
    me {
      id
      email
      isAdmin
      rooli {
        id
        role
      }
      etunimi
      sukunimi
      organisaatiot {
        id
        nimi
        active
      }
    }
  }
`
