import { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { VStack, Button, useToast, Text, Heading, Link } from '@chakra-ui/react'
import { LangSwitcher } from '../components/LangSwitcher'
import { PasswordInput } from '../components/PasswordInput'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/client'
import { CHECK_PASSWORD } from '../queries/check_password'

function Landing(props): ReactElement {
  const id = useParams<Record<string, string>>()

  const [password, setPassword] = useState<string>('')
  const [surveyId, setSurveyId] = useState<string>(id.id)

  const history = useHistory()
  const toast = useToast()
  const intl = useIntl()

  const [getPassword, { loading, data, error }] = useLazyQuery(CHECK_PASSWORD)
  const checkPassword = async () => {
    await getPassword({ variables: { id: surveyId, salasana: password } })
    // if(data.checkPassword)) {
    //   // setPatteristo(surveyId)
    // } else {
    //   toast({
    //     title: intl.formatMessage({
    //       id: 'wrong_password',
    //       defaultMessage: 'Väärä salasana',
    //     }),
    //     status: 'error',
    //     duration: 5000,
    //     isClosable: true,
    //   })
    // }
  }

  useEffect(() => {
    if (id.pw) {
      setPassword(id.pw)
    }
  }, [id.pw])
  useEffect(() => {
    if (id.pw && password === id.pw) {
      checkPassword()
    }
  }, [password, id.pw])
  useEffect(() => {
    if (error) {
      toast({
        title: intl.formatMessage({
          id: 'survey_ended',
          defaultMessage: 'Kysely umpeutunut',
        }),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    if (data?.testPassword) {
      window.localStorage.setItem('patteristo', surveyId)
      if (id.pw) {
        history.push({
          pathname: '/roleselection',
          state: {
            displayLangSwitcher: true,
          },
        })
      } else {
        history.push({
          pathname: 'roleselection',
          state: {
            displayLangSwitcher: false,
          },
        })
      }
    } else if (password.length > 0) {
      toast({
        title: intl.formatMessage({
          id: 'wrong_password',
          defaultMessage: 'Väärä salasana',
        }),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    // eslint-disable-next-line
  }, [data, error])

  function handleKeyDown(e: { key: string }) {
    if (e.key == 'Enter') {
      checkPassword()
    }
  }

  if (id.id) {
    return (
      <VStack spacing={8} pt={8}>
        <LangSwitcher setLanguage={props.setLanguage} />
        <Heading as="h1" fontSize="2xl">
          <FormattedMessage
            id="welcome"
            defaultMessage="Tervetuloa yhteistoiminnan tasomittariin!"
          />
        </Heading>
        {/* <Text>Kyselyn tunnus: {id.id}</Text> */}
        <PasswordInput setPassword={setPassword} handleKeyDown={handleKeyDown} />
        <Button aria-label="Aloita" variant="primary" onClick={checkPassword}>
          <FormattedMessage id="start" defaultMessage="Aloita" />
        </Button>
      </VStack>
    )
  }

  return (
    <VStack textAlign="left" marginLeft={400} marginRight={400} spacing={8} pt={8}>
      <Heading>Tervetuloa yhteistoiminnan tasomittarin käyttäjäksi!</Heading>
      <Text textAlign="left">
        Yhteistoiminnan tasomittari on Teknologiateollisuuden työnantajat ry:n ja Teollisuusliitto
        ry:n yhdessä suunnittelema työkalu henkilöstön ja työnantajan välisen yhteistoiminnan tason
        mittaamiseen. Mittaus tehdään työnantajan toimesta, yhdessä henkilöstön edustajien kanssa.
        Vastaajaryhminä toimivat työntekijät, työntekijöiden luottamushenkilöt, sekä työnantajan
        edustajat.
      </Text>
      <Text textAlign="left">
        Rekisteröitymisen tähän palveluun tekee työnantajan edustaja, joka voi olla esimerkiksi HR:n
        tai tuotannon edustaja. On kuitenkin tärkeää toteuttaa kysely yhteistyössä henkilöstön
        edustajien, sekä henkilöstön kanssa. Tässä kyselyssä työntekijöillä tarkoitetaan
        Teknologiateollisuuden työehtosopimuksen piirissä työskenteleviä työntekijöitä ja
        luottamushenkilöillä heidän edustajiaan. Työnantajan edustajina voivat vastata esimerkiksi
        yrityksen johto, HR:n edustajat, sekä työntekijöiden esihenkilöt.
      </Text>
      <Text textAlign="left">
        <b>HUOM!</b> Tämä työkalu on tarkoitettu ainoastaan Teknologiateollisuuden työnantajat ry:n
        jäsenyritysten ja niiden edustajien käyttöön. Rekisteröitymisen yhteydessä yrityksen
        jäsenyys tarkistetaan, eikä rekisteröitymislomakkeella pääse eteenpäin mikäli jäsenyyttä ei
        löydy. Jäsenyyteen liittyvissä kysymyksissä auttaa{' '}
        <Link href="mailto:jasenasiat@teknologiateollisuus.fi">
          jasenasiat(at)teknologiateollisuus.fi
        </Link>
        .
      </Text>
      <Text textAlign="left">
        Lisätietoa tasomittarista{' '}
        <Link href="mailto:tasomittari@tasomittari.fi">tasomittari@tasomittari.fi</Link>.
      </Text>
    </VStack>
  )
}

export default Landing
