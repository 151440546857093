import { gql } from '@apollo/client'

export const INDIVIDUAL_ANSWERS = gql`
  query getIndividualAnswers($patteristoId: String!, $filters: FilterInput) {
    vastaus: report(id: $patteristoId, filters: $filters) {
      index
      otsikko
      aliotsikko
      kysymys
      vaTyonantaja
      vaTyontekija
      vaLuottamusmies
      vhTyonantaja
      vhTyontekija
      vhLuottamusmies
      kaTyontekija
      kaTyonantaja
      kaLuottamusmies
      khTyonantaja
      khTyontekija
      khLuottamusmies
      reallyBadTyonantaja
      badTyonantaja
      cantTellTyonantaja
      goodTyonantaja
      reallyGoodTyonantaja
      reallyBadTyontekija
      badTyontekija
      cantTellTyontekija
      goodTyontekija
      reallyGoodTyontekija
      reallyBadLuottamusmies
      badLuottamusmies
      cantTellLuottamusmies
      goodLuottamusmies
      reallyGoodLuottamusmies
    }
  }
`
