import { gql } from '@apollo/client'

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization(
    $nimi: String
    $maakunta: Maakunnat
    $koko: Float
    $omistuspohja: Omistuspohjat
  ) {
    createOrganization(
      data: { nimi: $nimi, maakunta: $maakunta, koko: $koko, omistuspohja: $omistuspohja }
    ) {
      id
    }
  }
`
